

body {
  font-family: Calluna Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

/* Desktop HD */

 @media screen and (min-width: 1024px) { 
  .portTitle {
  font-size: 96px;
  font-family: BebasNeue-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}
.portHead {
  font-family: BebasNeue-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-size: 42px;
}
.portSubHead {
  font-family: Calluna Semibold;
  font-size: 24px;
}
.thumbPortSubHead {
  font-family: Calluna Semibold;
  font-size: 24px;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}
.portBody {
  font-size: 20px;
  font-family: Calluna Semibold;
}
.thumbText {
  position: absolute;


      width: 55%;
}
.thumbSubText {
  width: 70%;
}
 }

 /* Tablet */

 @media screen and (min-width: 576px) and (max-width: 1024px) { 
  .portTitle {
  font-size: 85px;
  font-family: BebasNeue-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}
.portHead {
  font-family: BebasNeue-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-size: 36px;
  padding-bottom: 3%;
}
.portSubHead {
  font-family: Calluna Semibold;
  font-size: 24px;
}
.thumbPortSubHead {
  font-family:Calluna Semibold;
  font-size: 24px;
}
.portBody {
  font-size: 22px;
  font-family: Calluna Semibold;
}
.thumbText {
  position: absolute;


      width: 60%;
}
.thumbSubText {
  width: 70%;
}
 }

 /* Mobile */
 @media screen and (min-width: 0px) and (max-width: 576px) { 
  .portTitle {
  font-size: 72px;
  font-family: BebasNeue-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  padding-bottom: 10px;
}
.portHead {
  font-family: BebasNeue-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-size: 26px;
  margin-bottom: 5%;
}
.portSubHead {
  font-family: Calluna Semibold;
  font-size: 18px;
}
.thumbPortSubHead {
  font-family: Calluna Semibold;
  font-size: 18px;
}
.portBody {
  font-size: 18px;
  font-family: Calluna Semibold;
}
.thumbText {
  position: absolute;
      top: 5%;
      left: 0%;
      width: 70%;
}
.thumbSubText {
  width: 70%;
}

 }
 

 .contactButton {
  background-color: white !important;
  color: #383A48 !important;

 }


 .contactButton:hover {
  background-color: #383A48 !important;
  color: white !important;

 }

 .formText {
   font-size: 20px !important;
  color: #383A48 !important;
 }

 .inputFields {
   border-radius: 0px !important;
   border-width: 3px !important;
   border-color: #383A48 !important;

   font-size: 20px !important;
   height: 70px;
 }

 .thumbDiv {
   cursor: pointer;
 }

 .thumbDiv:hover {
  left: 1%;
  text-decoration: none;

 }

code {
  font-family: DIN Alternate Bold;
}

/* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
font-family: 'DIN Alternate Bold';
font-style: normal;
font-weight: normal;
src: local('DIN Alternate Bold'), url('DINAlternate-Bold.woff') format('woff');
}

@font-face {
font-family: 'Futura Condensed PT Medium';
font-style: normal;
font-weight: normal;
src: local('Futura Condensed PT Medium'), url('futura-condensed-pt-medium-589e44ed1e3a5.woff') format('woff');
}

@font-face {
font-family: 'BebasNeue-Regular';
font-style: normal;
font-weight: normal;
src: local('BebasNeue-Regular'), url('BebasNeue-Regular.woff') format('woff');
}

@font-face {
font-family: 'Calluna Regular';
font-style: normal;
font-weight: normal;
src: local('Calluna Regular'), url('Calluna Regular.otf') format('woff');
}

@font-face {
font-family: 'Calluna Semibold';
font-style: normal;
font-weight: normal;
src: local('Calluna Semibold'), url('Calluna Semibold.otf') format('woff');
}

@font-face {
font-family: 'Calluna Bold';
font-style: normal;
font-weight: normal;
src: local('Calluna Bold'), url('Calluna Bold.otf') format('woff');
}
